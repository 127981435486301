.blip {
  aspect-ratio: 1/1;
  width: 40px;
  display: "flex";
  align-items: "center";
  justify-content: "center";
  transition: 0.2s;
}

.gray {
  background-color: gray;
}

.green {
  background-color: green;
}

.orange {
  background-color: orange;
}

@media (orientation: portrait) {
  .blip {
    width: min(7vw, 5vh);
    height: min(7vw, 5vh);
  }
}

@media (orientation: landscape) {
  .blip {
    width: max(28px, 3.5vw);
    height: max(28px, 3.5vw);
  }
}
