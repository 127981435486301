.score {
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  align-items: center;
  width: 100%;
  height: 100%;
  border-radius: 15px;
  background: rgba(0.8, 0, 0, 0.7);
  font-size: 2.5em;
  color: gold;
  font-weight: 900;
  text-shadow: 3px 3px gray;
}

.scoreContainer {
  display: flex;
  justify-content: center;
  align-items: center;
}

.scoreContainer .scoreNum {
  display: flex;
}

.scoreContainer .scoreIcon {
  display: flex;
  padding-right: 20px;
}
