ion-backdrop {
  opacity: 0.4 !important;
}

.app {
  background: linear-gradient(to bottom right, #2c3e50, #34495e, #4a69bd, #2c3e50);
  background-size: cover;
  height: 100%;
}

.flexContainer {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: center;
}

.blackboardBox {
  flex-basis: 45%;
  margin: 5px;
}

.scoreBox {
  flex-basis: 45%;
  margin: 5px;
}

@media (orientation: portrait) {
  .rectangleBox {
    width: 100%;
    margin: 5px;
  }
}

@media (orientation: portrait) and (max-width: 500px) {
  .app {
    padding-top: 50px;
  }
}

@media (orientation: landscape) {
  .rectangleBox {
    width: 50%;
    margin: 5px;
  }

  .controlArea {
    width: 40vw;
  }
}

.block {
  display: flex;
  justify-content: center;
  width: 25%;
  height: 100%;
  text-align: center;
}

@font-face {
  font-family: "chawp";
  src: local("chawp"), url(../fonts/chawp.ttf) format("truetype");
}

@font-face {
  font-family: "orbitron";
  src: local("orbitron"), url(../fonts/orbitron.ttf) format("truetype");
}

.headerTuples {
  display: flex;
  flex-direction: row;
  align-items: stretch;
  justify-content: space-around;
  height: 75%;
}

.alignedTuple {
  display: flex;
}

.tupelCaption {
  font-size: 0.8rem;
  color: green;
  text-align: center;
}

.mainAreaGeoPanel {
  display: flex;
  flex-direction: row;
  justify-content: center;
}

.delta {
  font-size: 0.7em;
  display: flex;
}

.bonus {
  color: green;
}

.malus {
  color: red;
}

.comment {
  font-size: 0.6em;
  display: flex;
}

.good {
  color: green;
}

.bad {
  color: red;
}

.controlBar {
  margin-top: -30px;
}

.countdown {
  font-size: 10em;
  font-weight: 900;
  color: red;
  z-index: 9999;
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
}

@keyframes pulse {
  0% {
    transform: scale(1);
  }

  50% {
    transform: rotate(10deg) scale(1.4);
    color: yellow;
  }
  100% {
    transform: scale(1);
  }
}
