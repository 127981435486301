.currentCalculation {
  font-family: "chawp";
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 100%;
  font-size: 3.5em;
  color: white;
  background-color: black;
  text-align: center;
  line-height: 1.5;
  border-radius: 15px;
}

.calculationFactored {
  font-size: 2rem;
}

.invisible {
  visibility: hidden;
}
