.controlContainer {
  width: 100%;
  border-radius: 15px;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  padding: 0.5em;
}

.actionsContainer {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
}

ion-button {
  text-wrap: wrap;
}
