.buttonContainer {
  display: flex;
  flex-direction: row;
  justify-content: center;
}

.addButton {
  display: flex;
  font-size: 0.8em;
  flex-basis: 25%;
}

.powerup {
  font-size: 0.6em;
  position: absolute;
  bottom: 3px;
  right: -9px;
  color: white !important;
}

.bonused {
  color: lightgreen !important;
  font-weight: 900;
  font-size: 1.5em;
  transition: 1s;
}

@media (orientation: portrait) {
  .addButton {
    flex-basis: 25%;
  }
}
