.highscoreContainer {
  overflow-y: scroll;
  background: #444;
  color: white;
  font-family: "orbitron";
  padding: 0;
  margin: 0;
}

.highscoreHeader {
  height: 60px;
  color: yellow;
  margin: 10px 0 0 0;
  display: flex;
  align-items: center;
  font-size: 2em;
}

.latestScore {
  font-size: 2.5em;
  justify-content: center;
  display: flex;
  padding: 20px 0;
  color: gold;
  font-weight: 900;
  text-shadow: 3px 3px gray;
}

.recentScore {
  color: gold;
  font-weight: bold;
}

.gameTypeHeader {
  height: 40px;
  background: orange;
  margin: 0;
  display: flex;
  align-items: center;
  font-size: 1.2em;
  font-weight: 900;
}

.left-aligned {
  text-align: left;
}

ion-chip.gold {
  --background: gold;
  --color: yellow;
  font-weight: 700;
  text-align: center;
  font-size: large;
}

ion-chip.silver {
  --background: silver;
  --color: white;
  font-weight: 700;
}

ion-chip.bronze {
  --background: #cd7f32;
  --color: brown;
  font-weight: 700;
}

ion-chip.default {
  --color: white;
  font-weight: 700;
}

.switchBar {
  height: 50px;
  border-top: 1px solid #bbb;
}

.monthBar {
  height: 40px;
  border-top: 1px solid #bbb;
  text-align: center;
  line-height: 40px;
  user-select: none;
}

.nextMonth {
  float: right;
  padding-right: 10px;
  margin-top: 2px;
}

.previousMonth {
  float: left;
  padding-left: 10px;
  margin-top: 2px;
}

.inactive {
  pointer-events: none;
  color: #eee;
}

.tab {
  text-align: center;
  width: 50%;
  display: inline-block;
  height: 50px;
  line-height: 50px;
  color: white;
  background-color: #aaa;
}
.activeTab {
  background-color: gray;
  color: yellow;
  font-weight: 900;
  text-shadow: 2px 2px #444;
}

ion-label {
  display: flex;
  justify-content: center;
  align-items: center;
}
