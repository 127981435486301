.tupleContainer {
  display: flex;
  overflow: hidden;
  align-items: center;
  justify-content: center;
  height: 100%;
}

.fixedHeightContainer {
  background: darkblue;
  display: flex;
  flex-direction: row;
}
